.engine__home {
  font-family: @font-page;
  max-width: @tablet;
  background-color: white;
  padding: 20px;
  margin: 0 auto 60px;
  border-radius: 4px;
  @media(min-width:@tablet) and (max-width:1150px){
    max-width: calc(100% - 200px);
  }
  .engine {
    &__title {
      font-size: 2.2rem;
      margin: 0 0 1rem;

      @media (min-width: @mobile-medium) {
        font-size: 3rem;
      }
    }

    &__subtitle {
      text-align: center;
      color: #3e3d48;
      font-family: "unna";
      font-size: 1.5rem;
      font-style: italic;
      margin-bottom: 2rem;
    }

    &__block {
      &-guestRooms { 
        display: none;
        &.open {
          display: block;

          @media (min-width: @mobile-medium) {
            display: flex;
          }
        }
      }

      &-main {
        @media (min-width: @mobile-medium) {
          display: flex;
        }
      }
    }

    &__field {
      margin: 0 6px;
      &-destination_wrapper,
      &-rooms_wrapper {
        @media (min-width: @mobile-medium) {
          width: 35%;
          position: relative;
          top: 5px;
        }

        @media (min-width: @screen) {
          top: -5px;
        }
      }

      &-attendees_wrapper,
      &-bedroom_wrapper {
        @media (min-width: @mobile-medium) {
          width: 15%;

          input {
            padding: 15px 10px 15px;
          }          
        }        
      }

      &-date_wrapper {
        @media (min-width: @mobile-medium) {
          width: 50%;
          display: flex;
          margin: 0 6px;

          .engine__field-dateIn {
            margin-right: 6px;
            width: calc(50% - 6px);
          }

          .engine__field-dateOut {
            margin-left: 6px;
            width: calc(50% - 6px);
          }
        }
      }

      &-toggle_wrapper {
        margin: 60px 0 48px;

        @media (min-width: @mobile-medium) {
          margin: 48px 0 24px;
        }
      }

      &-input {
        border-radius: 4px;        
      }

      &-toggle_link{
        padding: 6px 25px 4px 50px;
        &::before {
          background-color: white;
          border-radius: 2px;
          border: 1px solid #1264a3;
          content: "";
          height: 20px;
          left: 25px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
        }

        &::after { 
          content: none;
        }

        &.open {
          &:before {
            color: #068484;
            content: "✓";
            font-size: 24px;
            line-height: 20px;
          }
        }
      }
    }

    &__cta {
      display: inline-block;
      margin-top: 40px;
      text-align: center;

      &-btn{
        width: 100%;
        @media (min-width: @mobile-medium) {
          .colWidth(3);
          max-width: 200px;
        }
      }
    }
  }
}

.ahAutocomplete {
  min-width: auto !important;
}
