@import "../../partials/_common";

.section {
  &--hotel-finder {
    &__grid {
      background-color: @greenLightBgColor;
      color: @textColor;
      padding: 40px;
      width: 100%;

      @media (min-width: @tablet) {
        float: left;
        width: 47%;
        margin: 40px 0 40px 3%;
        position: relative;
      }
    }

    &__description {
      text-align: center;
    }

    &__img {
      background-color: white;
      border-radius: 50%;
      margin: 40px auto 70px;
      width: 50%;
    }

    &__title {
      font-family: @font-heading;
      font-size: 1.875rem;
    }

    &__subtitle {
      font-family: @font-banner;
      font-size: 1.875rem;
    }

    &__text {
      line-height: 1.625rem;
      padding: 40px 20px;

      @media (min-width: @tablet) {
        margin-bottom: 40px;
      }
    }

    &__cta {
      padding: 20px 0;
      text-align: center;

      @media (min-width: @tablet) {
        bottom: 40px;
        position: absolute;
        width: calc(100% - 80px);
      }
    }

    &__button {
      background-color: @buttonBgColor;
      color: white;
      font-size: 1rem;
      margin-right: 10px;
      padding: 12px 18px;

      &:hover {
        background: @buttonBgHoverColor !important;
        border-color: @buttonBgHoverColor !important;
      }
    }
  }
}
