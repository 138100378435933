@nItem1: #c0a450;
@nItem2: #f3f2ed;
@nItem3: #012b41;
@nItem4: #b8c9c1;
@nItem5: #012b41;
@nItem6: #f3f2ed;
@nItem7: #012b41;
@nItem8: #012b41;
@nItem9: #f3f2ed;

.create-event {
  &:extend(.container);

  @media(min-width: @screen-lg-min) {
    width: 1151px;
  }

  ol,
  ul {
    //Reset
    list-style: none;
    padding: 0;

    @media(min-width: @screen-sm-min) {
      height: auto;
      min-height: 785px;
    }

    li {
      &:nth-child(1):hover a:before {
        background: @nItem1;
      }

      &:nth-child(2):hover a:before {
        background: @nItem2;
      }

      &:nth-child(3):hover a:before {
        background: @nItem3;
      }

      &:nth-child(4):hover a:before {
        background: @nItem4;
      }

      &:nth-child(5):hover a:before {
        background: @nItem5;
      }

      &:nth-child(6):hover a:before {
        background: @nItem6;
      }

      &:nth-child(7):hover a:before {
        background: @nItem7;
      }

      &:nth-child(8):hover a:before {
        background: @nItem8;
      }

      &:nth-child(9):hover a:before {
        background: @nItem9;
      }
      img {
        max-width: 100%;
      }

      a:hover {
        text-decoration: none;

        img {
          margin-bottom: 44px;
        }
        /* fill title empty space with img bg color*/

        &:before {
          bottom: 0;
          content: '';
          height: 44px;
          left: 5px;
          position: absolute;
          right: 5px;
        }
        /* overlay */

        &:after {
          background: #012b41;
          bottom: 0;
          content: '';
          left: 5px;
          opacity: 0.3;
          position: absolute;
          right: 0;
          top: 0;
          width: ~'calc(100% - 10px)';
          z-index: 0;
        }
        /* move title span and text over the overlay */

        span {
          background: none;
          color: #fff;
          display: block;
          font-size: 1.25rem;
          height: 100%;
          left: 0;
          opacity: 1;
          padding: 42px 20px;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 1;
        }

        span:last-child {
          top: 60px;
        }
      }
      .make-md-column(4);
      margin: 12px auto;

      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(9) {
        a:hover:before {
          right: 0;
        }
        padding-right: 0;

        a:hover:after {
          width: ~'calc(100% - 5px)';
        }
      }

      span {
        background-color: #f0eee9;
        display: block;
        padding: 12px;
        text-align: center;

        + span {
          display: none;
        }
      }
    }
  }
}
