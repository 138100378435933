@import "../../partials/_common";

.section {
  &--club {
    &__grid {
      background-color: @footerBgColor;
      color: white;
      padding: 40px;
      width: 100%;

      @media (min-width: @tablet) {
        float: left;
        width: 47%;
        margin: 40px 3% 40px 0;
        position: relative;
      }
    }

    &__description {
      text-align: center;
    }

    &__img {
      margin: 0 auto 40px;
      padding: 60px 0;
      transform: rotate(-10deg);
      width: 65%;
    }

    &__title {
      font-family: @font-heading;
      font-size: 1.875rem;
    }

    &__subtitle {
      font-family: @font-banner;
      font-size: 1.875rem;
    }

    &__text {
      line-height: 1.625rem;
      padding: 40px 20px;

      @media (min-width: @tablet) {
        margin-bottom: 40px;
      }
    }

    &__cta {
      padding: 20px 0;
      text-align: center;

      @media (min-width: @tablet) {
        bottom: 40px;
        position: absolute;
        width: calc(100% - 80px);
      }
    }

    &__button {
      background-color: white !important;
      border: 1px solid white !important;
      color: #1e1852 !important;
      font-size: 1rem;
      margin-right: 10px;
      padding: 12px 18px;

      &:hover {
        background: #e6e5eb !important;
        border-color: #e6e5eb !important;
      }
    }
  }
}
