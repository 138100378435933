
h2 {
text-align: center;

+ span {
  display: block;
  font-size: 1.25rem;
  text-align: center;

  + p {
    display: inline-block;
    font-size: 1rem;
    text-align: center;

    @media(min-width: @screen-lg-min) {
      margin: 1.25rem 9.375rem;
    }
  }
}

}
