@charset "UTF-8";
/**
 * index page styles
 */
/**
 * events component styles
 */
.create-event {
  margin-left: auto;
  margin-right: auto;
  padding-left: 5px;
  padding-right: 5px;
}

@media (min-width: 480px) {
  .create-event {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .create-event {
    width: 768px;
  }
}
@media (min-width: 1024px) {
  .create-event {
    width: 1024px;
  }
}
@media (min-width: 1366px) {
  .create-event {
    width: 1400px;
  }
}
h2 {
  text-align: center;
}

h2 + span {
  display: block;
  font-size: 1.25rem;
  text-align: center;
}

h2 + span + p {
  display: inline-block;
  font-size: 1rem;
  text-align: center;
}

@media (min-width: 1366px) {
  h2 + span + p {
    margin: 1.25rem 9.375rem;
  }
}
@media (min-width: 1366px) {
  .create-event {
    width: 1151px;
  }
}
.create-event ol,
.create-event ul {
  list-style: none;
  padding: 0;
}

@media (min-width: 768px) {
  .create-event ol,
.create-event ul {
    height: auto;
    min-height: 785px;
  }
}
.create-event ol li,
.create-event ul li {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  margin: 12px auto;
}

.create-event ol li:nth-child(1):hover a:before,
.create-event ul li:nth-child(1):hover a:before {
  background: #c0a450;
}

.create-event ol li:nth-child(2):hover a:before,
.create-event ul li:nth-child(2):hover a:before {
  background: #f3f2ed;
}

.create-event ol li:nth-child(3):hover a:before,
.create-event ul li:nth-child(3):hover a:before {
  background: #012b41;
}

.create-event ol li:nth-child(4):hover a:before,
.create-event ul li:nth-child(4):hover a:before {
  background: #b8c9c1;
}

.create-event ol li:nth-child(5):hover a:before,
.create-event ul li:nth-child(5):hover a:before {
  background: #012b41;
}

.create-event ol li:nth-child(6):hover a:before,
.create-event ul li:nth-child(6):hover a:before {
  background: #f3f2ed;
}

.create-event ol li:nth-child(7):hover a:before,
.create-event ul li:nth-child(7):hover a:before {
  background: #012b41;
}

.create-event ol li:nth-child(8):hover a:before,
.create-event ul li:nth-child(8):hover a:before {
  background: #012b41;
}

.create-event ol li:nth-child(9):hover a:before,
.create-event ul li:nth-child(9):hover a:before {
  background: #f3f2ed;
}

.create-event ol li img,
.create-event ul li img {
  max-width: 100%;
}

.create-event ol li a:hover,
.create-event ul li a:hover {
  text-decoration: none;
  /* fill title empty space with img bg color*/
  /* overlay */
  /* move title span and text over the overlay */
}

.create-event ol li a:hover img,
.create-event ul li a:hover img {
  margin-bottom: 44px;
}

.create-event ol li a:hover:before,
.create-event ul li a:hover:before {
  bottom: 0;
  content: "";
  height: 44px;
  left: 5px;
  position: absolute;
  right: 5px;
}

.create-event ol li a:hover:after,
.create-event ul li a:hover:after {
  background: #012b41;
  bottom: 0;
  content: "";
  left: 5px;
  opacity: 0.3;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 10px);
  z-index: 0;
}

.create-event ol li a:hover span,
.create-event ul li a:hover span {
  background: none;
  color: #fff;
  display: block;
  font-size: 1.25rem;
  height: 100%;
  left: 0;
  opacity: 1;
  padding: 42px 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.create-event ol li a:hover span:last-child,
.create-event ul li a:hover span:last-child {
  top: 60px;
}

@media (min-width: 1024px) {
  .create-event ol li,
.create-event ul li {
    float: left;
    width: 33.33333333%;
  }
}
.create-event ol li:nth-child(3),
.create-event ul li:nth-child(3),
.create-event ol li:nth-child(6),
.create-event ul li:nth-child(6),
.create-event ol li:nth-child(9),
.create-event ul li:nth-child(9) {
  padding-right: 0;
}

.create-event ol li:nth-child(3) a:hover:before,
.create-event ul li:nth-child(3) a:hover:before,
.create-event ol li:nth-child(6) a:hover:before,
.create-event ul li:nth-child(6) a:hover:before,
.create-event ol li:nth-child(9) a:hover:before,
.create-event ul li:nth-child(9) a:hover:before {
  right: 0;
}

.create-event ol li:nth-child(3) a:hover:after,
.create-event ul li:nth-child(3) a:hover:after,
.create-event ol li:nth-child(6) a:hover:after,
.create-event ul li:nth-child(6) a:hover:after,
.create-event ol li:nth-child(9) a:hover:after,
.create-event ul li:nth-child(9) a:hover:after {
  width: calc(100% - 5px);
}

.create-event ol li span,
.create-event ul li span {
  background-color: #f0eee9;
  display: block;
  padding: 12px;
  text-align: center;
}

.create-event ol li span + span,
.create-event ul li span + span {
  display: none;
}

/**
 * HTML5 Boilerplate
 */
a,
button {
  color: inherit;
}

body::after {
  clear: both;
  content: "";
  display: table;
}

body,
html {
  height: 100%;
  position: relative;
  width: 100%;
}

html {
  box-sizing: border-box;
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  border: 0;
  display: block;
  font-size: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

body {
  font: 16px sans-serif;
  margin: 0;
}

button,
input,
select,
textarea {
  font: 99% sans-serif;
}

code,
kbd,
pre,
samp {
  font-family: monospace, sans-serif;
}

a:active,
a:hover {
  outline: none;
}

ol,
ul {
  list-style: none;
  margin: 0;
}

ol {
  list-style-type: decimal;
}

nav li,
nav ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
}

small {
  font-size: 85%;
}

strong,
th {
  font-weight: bold;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

pre {
  padding: 15px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

textarea {
  overflow: auto;
}

q {
  font-style: italic;
}

.ie6 legend,
.ie7 legend {
  margin-left: -7px;
}

input[type=radio],
input[type=checkbox] {
  vertical-align: middle;
}

.ie7 input[type=checkbox] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

input[type=button],
input[type=submit],
input[type=image],
button,
label {
  cursor: pointer;
}

button,
input,
select,
textarea {
  margin: 0;
}

.no-boxshadow input:invalid,
.no-boxshadow textarea:invalid {
  background-color: #f0dddd;
}

::selection {
  background: #0a246a;
  color: #fff;
  text-shadow: none;
}

a:link {
  -webkit-tap-highlight-color: rgba(45, 54, 142, 0.1);
}

button {
  overflow: visible;
  width: auto;
}

.ie7 img {
  -ms-interpolation-mode: bicubic;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

/*ne marche pas sous ie7*/
img {
  display: block;
  height: auto;
  max-width: 100%;
}

iframe {
  display: block;
  max-width: 100%;
  width: 100%;
}

input[type=text],
textarea,
input[type=submit],
input[type=password],
input[type=number],
input[type=email],
input[type=date] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

select {
  -webkit-border-radius: 0;
  border: 0;
  cursor: pointer;
  text-indent: 0;
  text-overflow: "";
}

select::-ms-expand {
  background: #fff;
  border: 0;
}

a {
  text-decoration: none;
}

.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* -- VARIABLES -- */
/* backgrounds */
/* textes */
/* b2b colors common*/
/* image path our-event-types*/
/** for typography file */
/* Devices Breakpoints normals*/
/** search-bar Breakpoint  */
/** Others Breakpoints  */
/* -- "GRID" -- */
/**
 * Typography partial styles
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Arial";
  line-height: 1.25;
  margin-top: 0.625rem;
}

h1 {
  font-size: 1.719rem;
}

h2 {
  font-size: 1.641rem;
}

h3 {
  font-size: 0.938rem;
}

h4 {
  font-size: 0.859rem;
}

h5 {
  font-size: 0.781rem;
}

h6 {
  font-size: 0.703rem;
}

html {
  color: #1a1e29;
  font-family: "Arial";
  font-weight: 400;
  line-height: 1.125;
}

p {
  margin-top: 0.625rem;
}

.error {
  color: #c70000;
}

input[type=text] {
  box-sizing: border-box;
}

.breadcrumb-container {
  color: #3e3d48;
  display: none;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.25rem;
  margin: 0 auto;
  padding: 0 1.25rem 1.25rem;
  position: relative;
  top: 1.563rem;
}

@media (min-width: 768px) {
  .breadcrumb-container {
    display: block;
  }
}
.breadcrumb-container__link {
  color: #1264a3;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.breadcrumb-container__link.btn-breadcrumb {
  text-decoration: none;
}

.breadcrumb-container__item:not(:first-child)::before {
  border: solid #3e3d48;
  border-width: 0 1px 1px 0;
  content: "";
  cursor: text;
  display: inline-block;
  margin: 0 10px 1px 10px;
  padding: 3px;
  transform: rotate(-45deg);
}

html {
  color: #3e3d48;
}

html body {
  font-family: "Roboto", Arial, sans-serif;
}

html body.has-emergency .breadcrumb-container {
  margin: 0.75rem auto;
}

.body-container section {
  margin: 0 auto;
  max-width: 1400px;
}

.body-container .link {
  color: #1264a3;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.body-container .banner-container {
  padding: 1.25rem 1.25rem 0 1.25rem;
  text-align: center;
}

@media (min-width: 768px) {
  .body-container .banner-container {
    padding: 1.875rem 1.25rem;
  }
}
.body-container .banner-container__title {
  color: #252339;
  font-family: "Montserrat", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  overflow-wrap: break-word;
  font-size: 2.2rem;
  line-height: 3rem;
}

.body-container .banner-container__paragraph {
  color: #3e3d48;
  font-family: "Unna";
  font-style: italic;
  font-weight: normal;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.body-container .banner-container__paragraph-small {
  color: #3e3d48;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}

.body-container .btn-breadcrumb {
  background: none;
  border: 0;
  padding: 0;
  text-decoration: none;
}

.roundButton {
  background: #1E1852;
  border-radius: 60px;
  color: white;
  cursor: pointer;
}

.roundButton:hover {
  background: #38326c;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.section--club__grid {
  background-color: #050033;
  color: white;
  padding: 40px;
  width: 100%;
}

@media (min-width: 1024px) {
  .section--club__grid {
    float: left;
    width: 47%;
    margin: 40px 3% 40px 0;
    position: relative;
  }
}
.section--club__description {
  text-align: center;
}

.section--club__img {
  margin: 0 auto 40px;
  padding: 60px 0;
  transform: rotate(-10deg);
  width: 65%;
}

.section--club__title {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 1.875rem;
}

.section--club__subtitle {
  font-family: "Unna";
  font-size: 1.875rem;
}

.section--club__text {
  line-height: 1.625rem;
  padding: 40px 20px;
}

@media (min-width: 1024px) {
  .section--club__text {
    margin-bottom: 40px;
  }
}
.section--club__cta {
  padding: 20px 0;
  text-align: center;
}

@media (min-width: 1024px) {
  .section--club__cta {
    bottom: 40px;
    position: absolute;
    width: calc(100% - 80px);
  }
}
.section--club__button {
  background-color: white !important;
  border: 1px solid white !important;
  color: #1e1852 !important;
  font-size: 1rem;
  margin-right: 10px;
  padding: 12px 18px;
}

.section--club__button:hover {
  background: #e6e5eb !important;
  border-color: #e6e5eb !important;
}

.section--hotel-finder__grid {
  background-color: #eef7f7;
  color: #3e3d48;
  padding: 40px;
  width: 100%;
}

@media (min-width: 1024px) {
  .section--hotel-finder__grid {
    float: left;
    width: 47%;
    margin: 40px 0 40px 3%;
    position: relative;
  }
}
.section--hotel-finder__description {
  text-align: center;
}

.section--hotel-finder__img {
  background-color: white;
  border-radius: 50%;
  margin: 40px auto 70px;
  width: 50%;
}

.section--hotel-finder__title {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 1.875rem;
}

.section--hotel-finder__subtitle {
  font-family: "Unna";
  font-size: 1.875rem;
}

.section--hotel-finder__text {
  line-height: 1.625rem;
  padding: 40px 20px;
}

@media (min-width: 1024px) {
  .section--hotel-finder__text {
    margin-bottom: 40px;
  }
}
.section--hotel-finder__cta {
  padding: 20px 0;
  text-align: center;
}

@media (min-width: 1024px) {
  .section--hotel-finder__cta {
    bottom: 40px;
    position: absolute;
    width: calc(100% - 80px);
  }
}
.section--hotel-finder__button {
  background-color: #1E1852;
  color: white;
  font-size: 1rem;
  margin-right: 10px;
  padding: 12px 18px;
}

.section--hotel-finder__button:hover {
  background: #38326c !important;
  border-color: #38326c !important;
}

/* VARIABLES */
/* Devices Breakpoints normals*/
/* END VARIABLES*/
.engine {
  color: #3e3d48;
  margin: 0 auto;
  padding: 0 5%;
  /*duet-date-picker*/
}

.engine__error-messages {
  background: #fcebec;
  border-radius: 0.375rem;
  color: #c70000;
  display: none;
  margin: 0 auto 1rem;
  padding: 1.25rem;
}

.engine__error-messages_nb {
  font-size: 1rem;
  left: 2rem;
  margin: 0;
  position: relative;
  top: -1.375rem;
}

.engine__error-messages_list {
  margin: -1rem 0 0 0;
}

.engine__error-messages_item {
  list-style: initial;
  margin: 0.375rem 0 0 1rem;
  padding: 0.375rem 0;
}

.engine__error-messages_btn {
  background-color: transparent;
  border: 0;
  color: #c70000;
  cursor: pointer;
  text-align: left;
}

.engine__error-messages.expanded {
  display: block;
}

.engine .err {
  border: 1px solid #c70000 !important;
}

.engine__title {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 1.375rem;
  text-align: center;
}

@media (min-width: 1200px) {
  .engine__title {
    text-align: left;
  }
}
.engine__field {
  position: relative;
  width: 100%;
}

.engine__field-label {
  display: block;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 0.875rem;
  margin: 12px 0 0;
  min-height: 40px;
}

@media (min-width: 1200px) {
  .engine__field-label {
    margin: 12px 0 4px;
  }
}
.engine__field-label span {
  color: #3e3d48;
  display: block;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.25rem;
}

.engine__field-label small {
  color: #6f6e77;
  display: block;
  font-size: 12px;
}

.engine__field-input {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #8b8a93;
  display: block;
  font-size: 1rem;
  height: auto;
  line-height: normal;
  margin: 10px 0;
  padding: 15px 10px 16px;
  width: 100%;
  font-family: "Roboto", Arial, sans-serif;
}

.engine__field-input.readonly {
  background-color: transparent;
  border: 0;
  padding: 0;
  text-align: center;
  width: 20px;
}

@media (min-width: 1200px) {
  .engine__field-input {
    margin: 0;
  }
}
.engine__field-date {
  width: 100%;
}

.engine__field-rooms--btn {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #8b8a93;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  height: auto;
  line-height: normal;
  padding: 16px 10px 15px;
  text-align: left;
  width: 100%;
}

@media (min-width: 1200px) {
  .engine__field-rooms--btn {
    margin: 0;
  }
}
.engine__field-rooms--popin {
  background: white;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: none;
  margin-top: 10px;
  padding: 20px 10px;
  position: absolute;
  width: 100%;
}

@media (min-width: 480px) {
  .engine__field-rooms--popin {
    width: auto;
  }
}
@media (min-width: 1200px) {
  .engine__field-rooms--popin {
    padding: 20px;
    width: auto;
  }
}
.engine__field-rooms--popin.open {
  display: block;
  z-index: 10;
}

.engine__field-rooms--popin_room,
.engine__field-rooms--popin_details {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.engine__field-rooms--popin_room fieldset,
.engine__field-rooms--popin_details fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (min-width: 480px) {
  .engine__field-rooms--popin_room-wrapper,
.engine__field-rooms--popin_details-wrapper {
    display: -ms-flexbox;
    display: flex;
  }
}
.engine__field-rooms--popin_room-row,
.engine__field-rooms--popin_details-row {
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
}

.engine__field-rooms--popin_room-label,
.engine__field-rooms--popin_details-label {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 16px;
  padding: 0 16px 0 0;
}

.engine__field-rooms--popin_room-children,
.engine__field-rooms--popin_details-children {
  margin-left: 16px;
}

@media (min-width: 480px) {
  .engine__field-rooms--popin_room-children,
.engine__field-rooms--popin_details-children {
    margin-left: 32px;
  }
}
.engine__field-rooms--popin_room-adults,
.engine__field-rooms--popin_details-adults {
  width: 50%;
}

.engine__field-rooms--popin_room {
  margin-bottom: 24px;
}

.engine__field-rooms--popin_details {
  -ms-flex-direction: column;
  flex-direction: column;
}

.engine__field-rooms--popin_details-container {
  margin-bottom: 32px;
  background-color: #f3f1ed;
  margin-bottom: 16px;
  padding: 8px;
}

@media (min-width: 480px) {
  .engine__field-rooms--popin_details-container {
    padding: 16px;
  }
}
.engine__field-rooms--popin_details-age {
  display: -ms-flexbox;
  display: flex;
}

.engine__field-rooms--popin_details-age_item {
  padding: 0;
}

.engine__field-rooms--popin_details-age_item:first-child {
  margin-left: auto;
}

.engine__field-rooms--popin_details-age_item:nth-child(2) {
  padding: 0 10px;
}

.engine__field-rooms--popin_details-age_item select {
  padding: 10px;
  width: 100%;
}

.engine__field-rooms--popin .controls {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
}

.engine__field-rooms--popin .controls-btn {
  background: 0;
  border-radius: 16px;
  border: 1px solid #068484;
  cursor: pointer;
  height: 32px;
  position: relative;
  width: 32px;
}

.engine__field-rooms--popin .controls-btn::before,
.engine__field-rooms--popin .controls-btn::after {
  background: #068484;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.engine__field-rooms--popin .controls-btn::before {
  content: "";
  height: 2px;
  width: 12px;
}

.engine__field-rooms--popin .controls-btn::after {
  height: 12px;
  width: 2px;
}

.engine__field-rooms--popin .controls-btn:disabled {
  color: #dadadd;
  border-color: #dadadd;
}

.engine__field-rooms--popin .controls-btn:disabled::before,
.engine__field-rooms--popin .controls-btn:disabled::after {
  background: #dadadd;
}

.engine__field-rooms--popin .controls-btn_plus {
  margin: 0 0 0 2px;
}

@media (min-width: 480px) {
  .engine__field-rooms--popin .controls-btn_plus {
    margin: 0 0 0 10px;
  }
}
.engine__field-rooms--popin .controls-btn_plus::after {
  content: "";
}

.engine__field-rooms--popin .controls-btn_minus {
  margin: 0 2px 0 0;
}

@media (min-width: 480px) {
  .engine__field-rooms--popin .controls-btn_minus {
    margin: 0 10px 0 0;
  }
}
.engine__field-rooms--popin .controls-btn.disabled {
  border-color: #ecedee;
  cursor: default;
}

.engine__field-rooms--popin .controls-btn.disabled::before,
.engine__field-rooms--popin .controls-btn.disabled::after {
  background: #ecedee;
}

.engine__field-link {
  color: #1264a3;
  font-size: 1rem;
  line-height: 1.375rem;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.engine__field-link_carto,
.engine__field-link_group {
  padding: 2px 32px 16px 24px;
  background: #1E1852;
  border-radius: 40px;
  color: white;
  display: block;
  text-align: center;
  text-decoration: none;
  transition: background 0.2s, border 0.2s;
}

@media (min-width: 480px) {
  .engine__field-link_carto,
.engine__field-link_group {
    display: inline-block;
  }
}
.engine__field-link_carto:hover,
.engine__field-link_group:hover,
.engine__field-link_carto:focus,
.engine__field-link_group:focus {
  background: #38326c;
}

.engine__field-link_carto svg,
.engine__field-link_group svg {
  fill: white;
  margin-right: 0.25rem;
  position: relative;
  top: 8px;
}

.engine__field-link_carto svg {
  height: 1.875rem;
  width: 1.875rem;
}

.engine__field-link_group svg {
  height: 2.1rem;
  width: 2.1rem;
}

.engine__field-toggle_wrapper {
  margin: 48px 0 16px;
}

.engine__field-toggle_wrapper hr {
  border: 0;
  border-top: 1px solid #1264a3;
}

.engine__field-toggle_link {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  background: white;
  border: 0;
  color: #1264a3;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  margin: -15px auto 0;
  padding: 0 50px 0 25px;
  position: relative;
}

.engine__field-toggle_link:before,
.engine__field-toggle_link:after {
  background: #1264a3;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  top: 50%;
  transition: transform 0.5s;
  width: 8px;
}

.engine__field-toggle_link:after {
  right: 25px;
  transform: rotate(-45deg);
}

.engine__field-toggle_link:before {
  right: 30px;
  transform: rotate(45deg);
}

.engine__field-toggle_link.open:after {
  transform: rotate(45deg);
}

.engine__field-toggle_link.open:before {
  transform: rotate(-45deg);
}

.engine__field-checkbox_fieldset {
  border: 0;
  border-radius: 0;
  border-top: 1px solid #1264a3;
  margin: 32px 0 0 0;
  padding: 0 15px;
}

.engine__field-checkbox_fieldset_brands {
  border: 0;
  padding: 0;
  width: 100%;
}

.engine__field-checkbox_legend {
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0 10px;
}

.engine__field-checkbox_legend_brands {
  padding: 0;
}

.engine__field-checkbox_list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
}

.engine__field-checkbox_item {
  background: #fbfbfb;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  margin: 10px 5px;
}

@media (min-width: 768px) {
  .engine__field-checkbox_item {
    -ms-flex-preferred-size: calc(100% / 2 - 10px);
    flex-basis: calc(100% / 2 - 10px);
  }
}
@media (min-width: 1200px) {
  .engine__field-checkbox_item {
    -ms-flex-preferred-size: calc(100% / 3 - 10px);
    flex-basis: calc(100% / 3 - 10px);
  }
}
@media (min-width: 1366px) {
  .engine__field-checkbox_item {
    -ms-flex-preferred-size: calc(100% / 4 - 10px);
    flex-basis: calc(100% / 4 - 10px);
  }
}
.engine__field-checkbox_label {
  font-size: 1rem;
}

.engine__field-checkbox_input {
  margin-right: 8px;
}

.engine__field-tooltip__container {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.engine__field-tooltip__content {
  -ms-flex-align: center;
  align-items: center;
  background: #eef7f7;
  border: 1px solid #8b8a93;
  border-radius: 6px;
  display: none;
  margin-top: 20px;
  padding: 0 10px;
}

.engine__field-tooltip__content.open {
  display: -ms-flexbox;
  display: flex;
}

.engine__field-tooltip__icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-align: center;
}

.engine__field-tooltip__icon-open {
  border: 1px solid #068484;
  border-radius: 1rem;
  color: #068484;
  height: 1.5rem;
  line-height: 1.5rem;
  left: calc(100% - 1.5rem);
  top: 8px;
  width: 1.5rem;
}

.engine__field-tooltip__icon-close {
  font-size: 1.4rem;
  padding-left: 10px;
}

.engine__field-tooltip__text {
  font-size: 0.8rem;
}

.engine duet-date-picker {
  padding: 0;
  border: 0;
}

.engine duet-date-picker .duet-date__input {
  border: 1px solid #8b8a93;
  border-radius: 4px;
}

.engine duet-date-picker .duet-date__input:disabled {
  background: #ecedee;
}

.engine__block-map {
  padding: 32px 0;
}

.engine .engine__cta {
  width: 100%;
}

.engine .engine__cta-btn.roundButton {
  background: #068484;
  border-radius: 30px;
  border-radius: 60px;
  border: 0;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  padding: 17px 12px 16px;
  text-align: center;
  transition: background 0.2s, border 0.2s;
  width: 100%;
  word-wrap: break-word;
}

.engine .engine__cta-btn.roundButton:hover,
.engine .engine__cta-btn.roundButton:focus {
  background: #007576;
}

fieldset {
  border: 1px solid #dadadd;
  border-radius: 4px;
  margin: 0;
  padding: 0 20px;
}

legend {
  color: #3e3d48;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
}

.engine__container .engine__block .ahAutocomplete {
  min-width: auto;
}

.engine__container .engine__block .ahAutocomplete__input .material-textfield input {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #8b8a93;
  display: block;
  font-size: 1rem;
  height: auto;
  line-height: normal;
  margin-top: 5px;
  padding: 15px 10px;
  width: 100%;
  box-shadow: none;
  font-family: "Roboto", Arial, sans-serif;
}

.engine__container .engine__block .ahAutocomplete__input .material-textfield input:focus {
  outline: 2px solid #1264a3;
  outline-offset: 1px;
}

[hidden] {
  width: 0;
}

.isLoading {
  opacity: 0.5;
  position: relative;
}

.isLoading:after {
  animation: spin 2s linear infinite;
  border-radius: 50%;
  border: 4px solid #068484;
  border-top: 4px solid transparent;
  content: "";
  display: block;
  height: 40px;
  width: 40px;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 40vh;
  z-index: 2;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.engine__home {
  font-family: "Roboto", Arial, sans-serif;
  max-width: 1024px;
  background-color: white;
  padding: 20px;
  margin: 0 auto 60px;
  border-radius: 4px;
}

@media (min-width: 1024px) and (max-width: 1150px) {
  .engine__home {
    max-width: calc(100% - 200px);
  }
}
.engine__home .engine__title {
  font-size: 2.2rem;
  margin: 0 0 1rem;
}

@media (min-width: 768px) {
  .engine__home .engine__title {
    font-size: 3rem;
  }
}
.engine__home .engine__subtitle {
  text-align: center;
  color: #3e3d48;
  font-family: "unna";
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 2rem;
}

.engine__home .engine__block-guestRooms {
  display: none;
}

.engine__home .engine__block-guestRooms.open {
  display: block;
}

@media (min-width: 768px) {
  .engine__home .engine__block-guestRooms.open {
    display: -ms-flexbox;
    display: flex;
  }
}
@media (min-width: 768px) {
  .engine__home .engine__block-main {
    display: -ms-flexbox;
    display: flex;
  }
}
.engine__home .engine__field {
  margin: 0 6px;
}

@media (min-width: 768px) {
  .engine__home .engine__field-destination_wrapper,
.engine__home .engine__field-rooms_wrapper {
    width: 35%;
    position: relative;
    top: 5px;
  }
}
@media (min-width: 1200px) {
  .engine__home .engine__field-destination_wrapper,
.engine__home .engine__field-rooms_wrapper {
    top: -5px;
  }
}
@media (min-width: 768px) {
  .engine__home .engine__field-attendees_wrapper,
.engine__home .engine__field-bedroom_wrapper {
    width: 15%;
  }

  .engine__home .engine__field-attendees_wrapper input,
.engine__home .engine__field-bedroom_wrapper input {
    padding: 15px 10px 15px;
  }
}
@media (min-width: 768px) {
  .engine__home .engine__field-date_wrapper {
    width: 50%;
    display: -ms-flexbox;
    display: flex;
    margin: 0 6px;
  }

  .engine__home .engine__field-date_wrapper .engine__field-dateIn {
    margin-right: 6px;
    width: calc(50% - 6px);
  }

  .engine__home .engine__field-date_wrapper .engine__field-dateOut {
    margin-left: 6px;
    width: calc(50% - 6px);
  }
}
.engine__home .engine__field-toggle_wrapper {
  margin: 60px 0 48px;
}

@media (min-width: 768px) {
  .engine__home .engine__field-toggle_wrapper {
    margin: 48px 0 24px;
  }
}
.engine__home .engine__field-input {
  border-radius: 4px;
}

.engine__home .engine__field-toggle_link {
  padding: 6px 25px 4px 50px;
}

.engine__home .engine__field-toggle_link::before {
  background-color: white;
  border-radius: 2px;
  border: 1px solid #1264a3;
  content: "";
  height: 20px;
  left: 25px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}

.engine__home .engine__field-toggle_link::after {
  content: none;
}

.engine__home .engine__field-toggle_link.open:before {
  color: #068484;
  content: "✓";
  font-size: 24px;
  line-height: 20px;
}

.engine__home .engine__cta {
  display: inline-block;
  margin-top: 40px;
  text-align: center;
}

.engine__home .engine__cta-btn {
  width: 100%;
}

@media (min-width: 768px) {
  .engine__home .engine__cta-btn {
    width: calc((100% + 24px) / 12 * 3 - 24px);
    max-width: 200px;
  }
}
.ahAutocomplete {
  min-width: auto !important;
}

/* -- VARIABLES -- */
.back-top {
  bottom: 1%;
  display: none;
  position: fixed;
  right: 2%;
}

@media (min-width: 768px) {
  .back-top {
    display: block;
  }
}
.back-top__link {
  bottom: 0;
  line-height: 0.688rem;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
}

.back-top__icon {
  background-color: #050033;
  border-radius: 50%;
  border: 2px solid white;
  color: white;
  display: inline-block;
  font-size: 1.25rem;
  height: 40px;
  line-height: 32px;
  margin-bottom: 6px;
  width: 40px;
}

.back-top__icon::before {
  content: "↑";
}

.back-top__text {
  color: grey;
  display: block;
  font-size: 0.563rem;
  font-weight: bold;
  text-transform: uppercase;
}